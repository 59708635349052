'use strict';

var slickConfigs = require('../config/slickConfigs');

$(document).ready(function () {
    var navigationModule = $('.carousel-2up-3up');

    function initSlider() {
        var ww = $(window).width();
       

        navigationModule.each(function (e, element) {
            var ww = $(window).width();

            if ($(element).hasClass('carousel-enabled')) {
                if ($(element).find('.carousel-2up') && ww < 768) {
                    $('.carousel-2up.carousel-enabled').not('.slick-initialized').slick(slickConfigs.carousel2up);
                }
                if ($(element).find('.carousel-3up')) {
                    $('.carousel-3up.carousel-enabled').not('.slick-initialized').slick(slickConfigs.carousel3up);
                }

                if ($(element).find('.carousel-4up.carousel-enabled') && ww <= 1024) {
                    $('.carousel-4up.carousel-enabled').not('.slick-initialized').slick(slickConfigs.carousel3up);
                }
            }
        })
    }

    initSlider();

 $(window).on('resize',function () {
    initSlider();
    var ww = $(window).width();

    if (ww > 1024 && $('.carousel-4up.carousel-enabled.slick-initialized').length) {
       
        $(window).on('breakpoint', function(event, _slick, breakpoint) {               
            function debounce(func, wait, immediate) {
                var timeout;
                return function() {
                  var context = this, args = arguments;
                  var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                  };
                  var callNow = immediate && !timeout;
                  clearTimeout(timeout);
                  timeout = setTimeout(later, wait);
                  if (callNow) func.apply(context, args);
                };
              };
            
                var unslickify = debounce(function() {
                    $('.carousel-4up.carousel-enabled.slick-initialized').slick('unfilterSlides');
                    $('.carousel-4up.carousel-enabled.slick-initialized').slick('unslick');
            
                }, 250);

                unslickify();
        });
    }
})
});
