'use strict';

/**
 * Reusable slick carousel configurations
 * @example - $('.product-carousel').slick(slickConfigs.pdp)
 */

module.exports = {
    productTiles: {
        infinite: false,
        speed: 300,
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    },
    pdpGallery: {
        infinite: false,
        speed: 300,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    },
    carousel2up: {
        infinite: false,
        dots: false,
        arrows: false,
        mobileFirst: true,
        centerMode: true,
        centerPadding: '0px',
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    },
    carousel3up: {
        infinite: false,
        dots: false,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1
                }
            }
        ]
    },
    upsell: {
        infinite: false,
        speed: 300,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    },
    textAndIconBlockView: {
        infinite: false,
        speed: 300,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    },
    productTilesPD: {
        infinite: true,
        speed: 300,
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            },{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    },
    quickview: {
        infinite: false,
        speed: 300,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
        touchMove: false
    },
    quickviewNav: {
        infinite: false,
        speed: 300,
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1
    },

    reviews: {
        infinite: true,
        speed: 300,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                }
            },{
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    },
    accoladesBar: {
        initialSlide: 0,
        infinite: false,
        slidesToShow: 1,
        speed: 300,
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                }
            }
        ]
    },
    heroCarousel: {
        infinite: false,
        speed: 300,
        dots: false,
        arrows: false,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    infinite: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    },

    recommendations: {
        infinite: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    },

    shortedMattress: {
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    },
};
